html {
    background-color: #14161b;
}

body {
    /* height: 100%; */
    /* overflow: auto; */
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    color: #f6f6f6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-size: 10px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    line-height: 13px;
}

code b {
    font-size: 11px;
}

a {
    color: #cccccc;
}

.maintitle {
    animation-name: fade-in;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 1.0s;
    animation-delay: 0.8s;
}

@keyframes fade-in {
    from {
        transition: opacity 0.5s linear;
        opacity: 0
    }
    to {
        opacity: 1
    }
}