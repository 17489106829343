.App {
    background-color: #14161b;
    text-align: center;
}

.App-header {
    margin-bottom: 30px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

.App-link {
    color: #5c6d89;
}

.App-thumb {
    position: relative;
    background-color: #1f1f1f;
    padding: 0px 15px 34px 15px;
}

.App-thumb-image {
    cursor: pointer;
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
}

.App-thumb-image-over {
    position: absolute;
    animation-name: semi-fade-in;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    margin-top: 14px;
    pointer-events: none;
    padding: 0;
    left: 15px;
    width: 270px;
}

.App-thumb-text {
    line-height: 13px;
}

.App-grid-container {
    display: grid;
    padding-left: 50px;
    padding-right: 50px;
    grid-template-columns: 300px 300px 300px;
    grid-gap: 20px;
    animation-name: fade-in;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 1.4s;
    animation-delay: 1.0s;
}

.App-grid-item {
    align-items: center;
}

@media (max-width: 1000px) {
    .App-grid-container {
        grid-template-columns: 300px 300px;
    }
}

@media (max-width: 670px) {
    .App-grid-container {
        padding-left: 0px;
        padding-right: 0px;
        grid-template-columns: 300px;
    }
}

@keyframes semi-fade-in {
    from {
        transition: opacity 0.5s linear;
        opacity: 0
    }
    to {
        opacity: 0.8
    }
}
