/* Modal Background */
.modal-container {
    background-color: #0c0c0ce8;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    overflow: auto;
    animation-name: fade-in;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
}

/* Modal Content/Box */
.modal-content {
    /* background-color: #1f1f1f; */
    /* width: 96%; */
    /* height: 96%; */
    animation-name: animatezoom;
    animation-duration: 0.6s;
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    /* margin: auto; */
    /* -webkit-transform: translate(0%, 30%);
    transform: translate(0%, 30%); */
}

.modal-fieldset {
    /* text-align: center; */
    border: 0;
    margin: 0 0 0 0;
    padding: 0;
}

.modal-linkField {
    background: #FFFFFF;
    border: 2px solid #979797;
    padding: 9px 5px 9px 10px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.50);
    border-radius: 3px;
    font-family: DINComp-Light, Helvetica, Arial, serif;
    font-size: 14px;
    color: #4A4A4A;
    text-align: left;
    width: auto;
}

/* The Close Button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 0px;
    color: #aaa;
    animation-name: fade-in;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
    width: 30px;
    height: 30px;
}

.modal-close-btn {
    background-color: transparent;
    color: #FFFFFF;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 13px;
    padding: 0px;
    font-weight: 600;
}

.modal-player {
    margin: auto;
    max-height: 580px;
}

.modal-close:hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-block {
    display: inline-block;
    position: relative;
    background-color: #1F1F1E;
    padding: 50px;
    margin: auto;
    text-align: left;
    width: 50%;
}

.modal-block iframe {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 580px;
}

.modal-title {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 17px;
    color: #f8ffff;
    margin-top: 18px;
    padding: 0px;
}

.modal-description {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #f6f6f6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 10px;
    font-size: 14px;
}

.modal-credits {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 10px;
    color: #cbcbcb;
    margin-top: 12px;
    line-height: 13px;
}

@media (max-width: 670px) {
    .modal-block {
        padding: 30px;
    }
    .modal-block iframe {
        height: 280px;
    }
}

/* Add Animation */
@keyframes animatezoom {
    from {
        transition: all 1.3s linear;
        transform: scale(1.1);
        opacity: 0
    }
    to {
        transform: scale(1);
        opacity: 1
    }
}

@keyframes fade-in {
    from {
        transition: opacity 0.5s linear;
        opacity: 0
    }
    to {
        opacity: 1
    }
}
